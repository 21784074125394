import { useContext } from "react";
import CheckIcon from "~/shared/icons/CheckIcon";
import ToasterContext from "./ToasterContext";
import ErrorIcon from "~/shared/icons/ErrorIcon";


export default function Toaster() {
    const context = useContext(ToasterContext);

    return <div className="toast toast-bottom toast-end">
        {
            context.toast && <div className={(!context.show ? "opacity-0" : "opacity-100") + (context.toast.success ? " alert-success text-success-content stroke-success-content": " alert-error text-error-content stroke-error-content") + " alert transition-opacity ease-in-out delay-150 duration-300"}>
                {
                    context.toast.success ? <CheckIcon/> : <ErrorIcon className="w-5 h-5"/>
                }
                <span>{context.toast?.message}</span>
            </div>
        }
    </div>;
}